import { FC } from 'react'
import { SVGProps } from '../Icon'

const EmojiSurprisedGradient: FC<SVGProps> = ({ className, id }) => (
    <svg
        width="90"
        height="91"
        viewBox="0 0 90 91"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M72.4979 30.4998C72.0795 30.4991 71.668 30.3935 71.301 30.1926C70.9341 29.9917 70.6234 29.7019 70.3975 29.3498C69.4656 28.0467 68.4639 26.7948 67.3968 25.5998C67.1488 25.3578 66.9538 25.0668 66.8243 24.7454C66.6947 24.424 66.6334 24.0791 66.6443 23.7328C66.6551 23.3864 66.7379 23.0461 66.8873 22.7334C67.0367 22.4207 67.2495 22.1425 67.5122 21.9164C67.7748 21.6904 68.0816 21.5213 68.4131 21.4201C68.7446 21.3189 69.0935 21.2876 69.4377 21.3284C69.7819 21.3691 70.1139 21.481 70.4126 21.6568C70.7112 21.8327 70.9701 22.0687 71.1726 22.3498C72.3984 23.721 73.5503 25.1563 74.6234 26.6498C74.7992 26.9272 74.9185 27.2365 74.9746 27.5601C75.0308 27.8836 75.0226 28.215 74.9507 28.5354C74.8787 28.8557 74.7443 29.1588 74.5551 29.4272C74.366 29.6957 74.1258 29.9242 73.8483 30.0998C73.4458 30.3598 72.9771 30.4986 72.4979 30.4998Z"
            fill={`url(#0_${id})`}
        />
        <path
            d="M44.9919 80.5C39.5203 80.4975 34.1254 79.2127 29.2401 76.7488C24.3549 74.2849 20.1154 70.7104 16.8617 66.3122C13.6081 61.914 11.4309 56.8146 10.5049 51.423C9.57897 46.0315 9.92997 40.4981 11.5298 35.2667C13.1296 30.0353 15.9337 25.2517 19.717 21.2997C23.5003 17.3477 28.1575 14.3373 33.3149 12.5101C38.4724 10.6829 43.9864 10.0898 49.4146 10.7784C54.8427 11.4669 60.0339 13.418 64.5714 16.475C65.1219 16.8463 65.5023 17.421 65.6289 18.0727C65.7555 18.7244 65.618 19.3997 65.2466 19.95C64.8752 20.5003 64.3004 20.8806 63.6485 21.0072C62.9967 21.1338 62.3212 20.9963 61.7708 20.625C56.2202 16.8822 49.5771 15.1028 42.8983 15.5698C36.2196 16.0368 29.8889 18.7234 24.9135 23.2022C19.9381 27.6811 16.6037 33.6948 15.441 40.2866C14.2783 46.8784 15.3539 53.6696 18.4968 59.5797C21.6397 65.4899 26.6693 70.1795 32.7854 72.9023C38.9015 75.6252 45.7527 76.2249 52.2491 74.6061C58.7454 72.9873 64.5136 69.2429 68.636 63.9687C72.7584 58.6945 74.9981 52.1935 74.9988 45.5C74.9988 44.837 75.2623 44.2011 75.7312 43.7322C76.2002 43.2634 76.8362 43 77.4994 43C78.1626 43 78.7987 43.2634 79.2676 43.7322C79.7365 44.2011 80 44.837 80 45.5C80 54.7826 76.3117 63.685 69.7464 70.2487C63.1811 76.8125 54.2766 80.5 44.9919 80.5Z"
            fill={`url(#1_${id})`}
        />
        <path
            d="M32.4895 43C30.4999 43 28.5918 42.2098 27.185 40.8033C25.7782 39.3968 24.9878 37.4891 24.9878 35.5V33C24.9878 31.0109 25.7782 29.1032 27.185 27.6967C28.5918 26.2902 30.4999 25.5 32.4895 25.5C34.4791 25.5 36.3872 26.2902 37.794 27.6967C39.2009 29.1032 39.9913 31.0109 39.9913 33V35.5C39.9913 37.4891 39.2009 39.3968 37.794 40.8033C36.3872 42.2098 34.4791 43 32.4895 43ZM32.4895 30.5C31.8263 30.5 31.1903 30.7634 30.7213 31.2322C30.2524 31.7011 29.9889 32.337 29.9889 33V35.5C29.9889 36.163 30.2524 36.7989 30.7213 37.2678C31.1903 37.7366 31.8263 38 32.4895 38C33.1527 38 33.7887 37.7366 34.2577 37.2678C34.7266 36.7989 34.9901 36.163 34.9901 35.5V33C34.9901 32.337 34.7266 31.7011 34.2577 31.2322C33.7887 30.7634 33.1527 30.5 32.4895 30.5Z"
            fill={`url(#2_${id})`}
        />
        <path
            d="M44.9908 71.75C43.6443 71.7176 42.3174 71.4202 41.0859 70.8749C39.8544 70.3296 38.7425 69.547 37.8136 68.5719C36.8847 67.5968 36.1571 66.4483 35.6724 65.192C35.1876 63.9357 34.9552 62.5962 34.9885 61.25V57.25C34.9552 55.9038 35.1876 54.5643 35.6724 53.308C36.1571 52.0517 36.8847 50.9032 37.8136 49.9281C38.7425 48.953 39.8544 48.1704 41.0859 47.6251C42.3174 47.0798 43.6443 46.7824 44.9908 46.75C46.3373 46.7824 47.6642 47.0798 48.8956 47.6251C50.1271 48.1704 51.2391 48.953 52.168 49.9281C53.0968 50.9032 53.8244 52.0517 54.3092 53.308C54.7939 54.5643 55.0263 55.9038 54.9931 57.25V61.25C55.0263 62.5962 54.7939 63.9357 54.3092 65.192C53.8244 66.4483 53.0968 67.5968 52.168 68.5719C51.2391 69.547 50.1271 70.3296 48.8956 70.8749C47.6642 71.4202 46.3373 71.7176 44.9908 71.75ZM44.9908 51.75C43.5999 51.8212 42.2935 52.4394 41.3567 53.4697C40.4198 54.5001 39.9284 55.8589 39.9896 57.25V61.25C39.9284 62.6411 40.4198 63.9999 41.3567 65.0303C42.2935 66.0606 43.5999 66.6788 44.9908 66.75C46.3817 66.6788 47.688 66.0606 48.6249 65.0303C49.5618 63.9999 50.0532 62.6411 49.9919 61.25V57.25C50.0532 55.8589 49.5618 54.5001 48.6249 53.4697C47.688 52.4394 46.3817 51.8212 44.9908 51.75Z"
            fill={`url(#3_${id})`}
        />
        <path
            d="M57.4944 43C55.5048 43 53.5967 42.2098 52.1899 40.8033C50.783 39.3968 49.9927 37.4891 49.9927 35.5V33C49.9927 31.0109 50.783 29.1032 52.1899 27.6967C53.5967 26.2902 55.5048 25.5 57.4944 25.5C59.484 25.5 61.3921 26.2902 62.7989 27.6967C64.2058 29.1032 64.9961 31.0109 64.9961 33V35.5C64.9961 37.4891 64.2058 39.3968 62.7989 40.8033C61.3921 42.2098 59.484 43 57.4944 43ZM57.4944 30.5C56.8312 30.5 56.1952 30.7634 55.7262 31.2322C55.2573 31.7011 54.9938 32.337 54.9938 33V35.5C54.9938 36.163 55.2573 36.7989 55.7262 37.2678C56.1952 37.7366 56.8312 38 57.4944 38C58.1576 38 58.7936 37.7366 59.2626 37.2678C59.7315 36.7989 59.995 36.163 59.995 35.5V33C59.995 32.337 59.7315 31.7011 59.2626 31.2322C58.7936 30.7634 58.1576 30.5 57.4944 30.5Z"
            fill={`url(#4_${id})`}
        />
        <defs>
            <linearGradient
                id={`0_${id}`}
                x1="75.0115"
                y1="21.311"
                x2="66.1951"
                y2="21.7631"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="var(--tw-gradient-from)" />
                <stop
                    offset="1"
                    stopColor="var(--tw-gradient-to, rgb(86 232 207 / 0))"
                />
            </linearGradient>
            <linearGradient
                id={`1_${id}`}
                x1="80"
                y1="10.5"
                x2="6.29228"
                y2="14.6501"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="var(--tw-gradient-from)" />
                <stop
                    offset="1"
                    stopColor="var(--tw-gradient-to, rgb(86 232 207 / 0))"
                />
            </linearGradient>
            <linearGradient
                id={`2_${id}`}
                x1="39.9913"
                y1="25.5"
                x2="24.1799"
                y2="26.2633"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="var(--tw-gradient-from)" />
                <stop
                    offset="1"
                    stopColor="var(--tw-gradient-to, rgb(86 232 207 / 0))"
                />
            </linearGradient>
            <linearGradient
                id={`3_${id}`}
                x1="54.9962"
                y1="46.75"
                x2="33.9015"
                y2="47.7007"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="var(--tw-gradient-from)" />
                <stop
                    offset="1"
                    stopColor="var(--tw-gradient-to, rgb(86 232 207 / 0))"
                />
            </linearGradient>
            <linearGradient
                id={`4_${id}`}
                x1="64.9961"
                y1="25.5"
                x2="49.1847"
                y2="26.2633"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="var(--tw-gradient-from)" />
                <stop
                    offset="1"
                    stopColor="var(--tw-gradient-to, rgb(86 232 207 / 0))"
                />
            </linearGradient>
        </defs>
    </svg>
)

export default EmojiSurprisedGradient
